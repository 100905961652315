exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collaborate-js": () => import("./../../../src/pages/collaborate.js" /* webpackChunkName: "component---src-pages-collaborate-js" */),
  "component---src-pages-downloads-poltergeist-downloads-js": () => import("./../../../src/pages/downloads/poltergeist-downloads.js" /* webpackChunkName: "component---src-pages-downloads-poltergeist-downloads-js" */),
  "component---src-pages-eula-js": () => import("./../../../src/pages/eula.js" /* webpackChunkName: "component---src-pages-eula-js" */),
  "component---src-pages-get-ad-free-poltergeist-ad-free-js": () => import("./../../../src/pages/get-ad-free/poltergeist-ad-free.js" /* webpackChunkName: "component---src-pages-get-ad-free-poltergeist-ad-free-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-poltergeist-js": () => import("./../../../src/pages/poltergeist.js" /* webpackChunkName: "component---src-pages-poltergeist-js" */),
  "component---src-pages-share-music-js": () => import("./../../../src/pages/share-music.js" /* webpackChunkName: "component---src-pages-share-music-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

